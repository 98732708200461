import { Component, Vue } from "vue-property-decorator";
import { WorkStatus } from "@/store/modules/activity.store";
import { Action, Getter, Mutation } from "vuex-class";

@Component
export class HasActivity extends Vue {
  @Getter("activity/viewing") activity!: Activity;

  @Action("activity/read") readActivity!: ActivityRead;

  @Mutation("activity/SET_VIEWING") setViewingActivity!: (payload: null) => void;

  @Action("setOfficeId") setOfficeId!: (officeId: number) => void;

  /**
   * @description Verify if there is an activity, if we can view it and if not load it.
   */
  async loadActivity() {
    if (this.activity && Number(this.$route.params.id) !== this.activity.id) {
      this.setViewingActivity(null);
    }

    if (!this.activity) {
      try {
        await this.readActivity({ id: Number(this.$route.params.id) });
      } catch (e: any) {
        if (e.status === 409) {
          const office_id = e.errors[0].params.office_id;
          if (office_id) {
            this.setOfficeId(office_id);

            window.location.reload();
          }
        }
      }
    }
  }
}
