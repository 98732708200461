
  import { HasActivity } from "@/mixins/activities/has-activity";
  import { Component, Mixins, Vue } from "vue-property-decorator";
  import { Action, Getter, Mutation } from "vuex-class";

  @Component({
    components: {
      PropertySearchPart: () => import("@/views/auth/dossier/parts/property-search.vue"),
      SellerPart: () => import("@/views/auth/dossier/parts/sellers.vue"),
      DossierHeader: () => import("@/views/auth/dossier/parts/header.vue"),
      NextTaskPart: () => import("@/views/auth/dossier/parts/next-task.vue"),
      PropertyInfoPart: () => import("@/views/auth/dossier/parts/property-info.vue"),
      MainPanelPart: () => import("@/views/auth/dossier/parts/main-panel.vue"),
      WorkStatusBar: () => import("@/views/auth/dossier/parts/work-status-bar.vue"),
      SellerInviteModal: () => import("@/components/modals/invite-seller.vue"),
      ActivityUpdateModal: () => import("@/components/modals/update-activity.vue"),
      ActivityStartupModal: () => import("@/components/modals/startup-activity.vue"),
      ActivityOpenModal: () => import("@/components/modals/open-activity.vue"),
      ActivityCloseModal: () => import("@/components/modals/close-activity.vue"),
      ActivityDeleteModal: () => import("@/components/modals/delete-activity.vue"),
      ActivityTransitionFailedModal: () => import("@/components/modals/activity-transition-failed-modal.vue"),
      ActivityLostReasonModal: () => import("@/components/modals/activity-lost-reason.vue"),
    },
  })
  export default class DossierRead extends Mixins(HasActivity) {
    @Getter("property/viewing") property!: Property;
    @Getter("currentOffice") officeId!: number;
    @Getter("auth/me") account!: CurrentAccount;

    @Mutation("property/SET_VIEWING") setViewingProperty!: (payload: null) => void;

    @Action("property/read") readProperty!: (payload: { activity_id: number }) => Promise<Property>;
    @Action("property/create") createProperty!: (payload: PropertyCreatePayload) => Promise<Property>;

    async mounted() {
      await this.loadActivity();

      if (this.activity && this.activity.office_id !== this.officeId) {
        if (!this.account.offices!.includes(this.activity.office_id)) {
          this.$router.push({ name: "error", params: { code: "401" } });
        } else {
          this.setOfficeId(this.activity.office_id);

          // @ts-ignore
          this.$router.go(this.$router.currentRoute);
        }
      }

      if (this.property && this.property.id !== this.activity.property_id) {
        this.setViewingProperty(null);
      }

      if (!this.property) {
        await this.readProperty({ activity_id: Number(this.$route.params.id) });
      }
    }
  }
